export const localStorageDarkTheme = 'darkTheme';
export const localStorageUserInfo = 'userInfo';
export const localStorageRecipeChecksum = 'recipeChecksum';
export const localStorageCategoryChecksum = 'categoryChecksum';
export const localStorageCommentChecksum = 'commentChecksum';
export const localStorageUserChecksum = 'userChecksum';
export const localStorageShoppingList = 'shoppingList';
export const sessionStorageFilteredCategories = 'filteredCategories';
export const sessionStorageFilteredUsers = 'filteredUsers';
export const sessionStorageSearchInIngredients = 'searchInIngredients';
export const sessionStorageSearchString = 'searchString';
export const sessionStorageSortingOrder = 'sortingOrder';
export const sessionStorageScrollPosition = 'scrollPosition';